.navbar{
  .navbar-nav{
    .nav-link{
      i.fa + p,
      i.nc-icon + p{
        margin-left: 7px;
      }
    }
  }
}
